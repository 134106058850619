/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Poco a poco se empieza a concienciar la gente del peligro que puede entrañar el multi-monopolio de google. El siguiente video lo he visto en ", React.createElement(_components.a, {
    href: "http://www.microsiervos.com/archivo/internet/porque-google-puede-dar-miedo.html",
    title: "El porqué de que google pueda dar miedo"
  }, "microsiervos"), ", y deja muy claro todo el imperio de google y lo que esto implica."), "\n", React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=R7yfV6RzE30]"), "\n", React.createElement(_components.p, null, "Un saludo"), "\n", React.createElement(_components.p, null, "PD: Si el video está subido a youtube, propiedad de google."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
